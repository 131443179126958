<template>
  <div class="public">
    <div class="container homeConsumptionstructure">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="item in forCodeData" :key="item.type">
          <div class="fl box-left">
            <div class="box-left-childtop">
              {{ item.type == 1 ? "消费人次：" : "预付卡交易金额：" }}
            </div>
            <div class="box-left-childtopval">
              {{ item.xfrq }}
              <span class="box-left-childtopvalcom">{{ item.xfrqcom }}</span>
            </div>
          </div>
          <div class="fr box-right">
            <div class="box-url"></div>
            <PieEpide
              :chartData="{
                seriesData: item.seriesData,
                Gender: item.Gender,
              }"
            >
            </PieEpide>
            <div class="box-left-childbottom">
              <div
                class="box-left-childbottomval"
                v-for="(itemval, index) in item.seriesData"
                :key="itemval.val"
              >
                <div
                  class="box-left-childbottomvalicon"
                  :style="{ background: forCodeDatacolor[index] }"
                ></div>
                <div>{{ itemval.name }}</div>
                <div class="box-left-childbottomvalpercentage">
                  {{ itemval.value }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 消费区域分布情况 地图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>消费人次区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 消费人群新增变化趋势 柱状图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                this.tabPosition2 == 1 ? "消费人次" : "预付卡交易金额"
              }}新增变化趋势</span
            >
          </div>
          <!-- <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="optionData"
            right="0"
            top="-35"
            @popupVal="popupVal"
          ></KcSelect> -->

          <KcSelect left="0" top="18" @popupVal="popupVal2"></KcSelect>
        </div>
        <div class="charts">
          <!-- <BarChart
            :chartData="barChartData"
            :legendData="['儿童', '少年', '青年', '中年', '老年']"
          ></BarChart> -->
          <LineChart
            :chartData="barChartData"
            v-loading="barChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="['儿童', '少年', '青年', '中年', '老年', '其他']"
            :units="units1"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal"></Tab>
      </div>
      <!-- 消费人群累计变化趋势 柱图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                this.tabPosition3 == 1 ? "消费人次" : "预付卡交易金额"
              }}累计变化趋势</span
            >
          </div>

          <!-- <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="optionData"
            right="0"
            top="-35"
            @popupVal="popupVal3"
          ></KcSelect> -->

          <KcSelect left="0" top="18" @popupVal="popupVal4"></KcSelect>
        </div>
        <div class="charts">
          <!-- <BarChart
            :chartData="barChartDatatwo"
            :legendData="['儿童', '少年', '青年', '中年', '老年']"
          ></BarChart> -->
          <LineChart
            :chartData="barChartDatatwo"
            v-loading="barChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="['儿童', '少年', '青年', '中年', '老年', '其他']"
            :units="units2"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal3"></Tab>
      </div>
      <!-- 消费人群按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                this.tabPosition4 == 1 ? "消费人次" : "预付卡交易金额"
              }}区域分布数量统计</span
            >
          </div>
        </div>
        <KcSelect
          isBlue
          keyLabel="haripinType"
          :optionData="optionData"
          right="10"
          top="-35"
          @popupVal="popupVal5"
        ></KcSelect>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template v-if="tabPosition4 == 1">
              <el-table-column
                prop="consumersNum"
                :key="1"
                label="总计"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.consumersNum }}</span>
                  <i
                    :class="
                      scope.row.consumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.consumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.consumersNumTit > 0
                        ? "+" + scope.row.consumersNumTit
                        : scope.row.consumersNumTit < 0
                        ? "" + scope.row.consumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="childConsumersNum"
                :key="2"
                :width="0.21 * $bodyWidth"
                label="儿童"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.childConsumersNum }}</span
                  ><i
                    :class="
                      scope.row.childConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.childConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.childConsumersNumTit > 0
                        ? "+" + scope.row.childConsumersNumTit
                        : scope.row.childConsumersNumTit < 0
                        ? "" + scope.row.childConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="juvenileConsumersNum"
                :key="3"
                label="少年"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{
                    scope.row.juvenileConsumersNum
                  }}</span
                  ><i
                    :class="
                      scope.row.juvenileConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.juvenileConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.juvenileConsumersNumTit > 0
                        ? "+" + scope.row.juvenileConsumersNumTit
                        : scope.row.juvenileConsumersNumTit < 0
                        ? "" + scope.row.juvenileConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="youthConsumersNum"
                :key="4"
                label="青年"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.youthConsumersNum }}</span
                  ><i
                    :class="
                      scope.row.youthConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.youthConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.youthConsumersNumTit > 0
                        ? "+" + scope.row.youthConsumersNumTit
                        : scope.row.youthConsumersNumTit < 0
                        ? "" + scope.row.youthConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="middleConsumersNum"
                :key="5"
                label="中年"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.middleConsumersNum }}</span
                  ><i
                    :class="
                      scope.row.middleConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.middleConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.middleConsumersNumTit > 0
                        ? "+" + scope.row.middleConsumersNumTit
                        : scope.row.middleConsumersNumTit < 0
                        ? "" + scope.row.middleConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="oldConsumersNum"
                :key="6"
                label="老年"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.oldConsumersNum }}</span
                  ><i
                    :class="
                      scope.row.oldConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.oldConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.oldConsumersNumTit > 0
                        ? "+" + scope.row.oldConsumersNumTit
                        : scope.row.oldConsumersNumTit < 0
                        ? "" + scope.row.oldConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column prop="oldConsumersNum" label="其他" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unkownConsumersNum }}</span
                  ><i
                    :class="
                      scope.row.unkownConsumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unkownConsumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unkownConsumersNumTit > 0
                        ? "+" + scope.row.unkownConsumersNumTit
                        : scope.row.unkownConsumersNumTit < 0
                        ? "" + scope.row.unkownConsumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition4 == 2">
              <el-table-column prop="amountNum" :key="1" label="总计" sortable :width="0.21 * $bodyWidth"
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.amountNum }}</span
                  ><i
                    :class="
                      scope.row.amountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.amountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.amountNumTit > 0
                        ? "+" + scope.row.amountNumTit
                        : scope.row.amountNumTit < 0
                        ? "" + scope.row.amountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="childAmountNum"
                :key="2"
                label="儿童"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.childAmountNum }}</span
                  ><i
                    :class="
                      scope.row.childAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.childAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.childAmountNumTit > 0
                        ? "+" + scope.row.childAmountNumTit
                        : scope.row.childAmountNumTit < 0
                        ? "" + scope.row.childAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="juvenileAmountNum"
                :key="3"
                label="少年"
                sortable
                :width="0.21 * $bodyWidth"
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.juvenileAmountNum }}</span
                  ><i
                    :class="
                      scope.row.juvenileAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.juvenileAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.juvenileAmountNumTit > 0
                        ? "+" + scope.row.juvenileAmountNumTit
                        : scope.row.juvenileAmountNumTit < 0
                        ? "" + scope.row.juvenileAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="youthAmountNum"
                :key="4"
                :width="0.21 * $bodyWidth"
                label="青年"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.youthAmountNum }}</span
                  ><i
                    :class="
                      scope.row.youthAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.youthAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.youthAmountNumTit > 0
                        ? "+" + scope.row.youthAmountNumTit
                        : scope.row.youthAmountNumTit < 0
                        ? "" + scope.row.youthAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="middleAmountNum"
                :key="5"
                :width="0.21 * $bodyWidth"
                label="中年"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.middleAmountNum }}</span
                  ><i
                    :class="
                      scope.row.middleAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.middleAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.middleAmountNumTit > 0
                        ? "+" + scope.row.middleAmountNumTit
                        : scope.row.middleAmountNumTit < 0
                        ? "" + scope.row.middleAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="oldAmountNum"
                :key="6"
                label="老年"
                :width="0.21 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.oldAmountNum }}</span
                  ><i
                    :class="
                      scope.row.oldAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.oldAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.oldAmountNumTit > 0
                        ? "+" + scope.row.oldAmountNumTit
                        : scope.row.oldAmountNumTit < 0
                        ? "" + scope.row.oldAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column prop="oldAmountNum" label="其他" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.unkownAmountNum }}</span
                  ><i
                    :class="
                      scope.row.unkownAmountNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.unkownAmountNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.unkownAmountNumTit > 0
                        ? "+" + scope.row.unkownAmountNumTit
                        : scope.row.unkownAmountNumTit < 0
                        ? "" + scope.row.unkownAmountNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 消费人群按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                this.tabPosition5 == 1 ? "消费人次" : "预付卡交易金额"
              }}领域/行业分布数量统计</span
            >
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="optionData"
            right="0"
            top="-35"
            @popupVal="popupVal6"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryName"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
            :row-style="showRow"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="industryName"
              :key="0"
              label="领域/行业"
              sortable
              :width="0.32 * $bodyWidth"
              marginBottom="5"
              fixed
            >
            </el-table-column>
            <template v-if="tabPosition5 == 1">
  <el-table-column
    prop="consumersNum"
    :key="1"
    label="总计"
    :width="0.21 * $bodyWidth"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.consumersNum }}</span>
      <i
        :class="
          scope.row.consumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.consumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.consumersNumTit > 0
            ? "+" + scope.row.consumersNumTit
            : scope.row.consumersNumTit < 0
            ? "" + scope.row.consumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="childConsumersNum"
    :key="2"
    label="儿童"
    :width="0.21 * $bodyWidth"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.childConsumersNum }}</span
      ><i
        :class="
          scope.row.childConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.childConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.childConsumersNumTit > 0
            ? "+" + scope.row.childConsumersNumTit
            : scope.row.childConsumersNumTit < 0
            ? "" + scope.row.childConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="juvenileConsumersNum"
    :key="3"
    label="少年"
    :width="0.21 * $bodyWidth"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.juvenileConsumersNum }}</span
      ><i
        :class="
          scope.row.juvenileConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.juvenileConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.juvenileConsumersNumTit > 0
            ? "+" + scope.row.juvenileConsumersNumTit
            : scope.row.juvenileConsumersNumTit < 0
            ? "" + scope.row.juvenileConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="youthConsumersNum"
    :key="4"
    :width="0.21 * $bodyWidth"
    label="青年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.youthConsumersNum }}</span
      ><i
        :class="
          scope.row.youthConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.youthConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.youthConsumersNumTit > 0
            ? "+" + scope.row.youthConsumersNumTit
            : scope.row.youthConsumersNumTit < 0
            ? "" + scope.row.youthConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="middleConsumersNum"
    :key="5"
    :width="0.21 * $bodyWidth"
    label="中年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.middleConsumersNum }}</span
      ><i
        :class="
          scope.row.middleConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.middleConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.middleConsumersNumTit > 0
            ? "+" + scope.row.middleConsumersNumTit
            : scope.row.middleConsumersNumTit < 0
            ? "" + scope.row.middleConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="oldConsumersNum"
    :key="6"
    :width="0.21 * $bodyWidth"
    label="老年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.oldConsumersNum }}</span
      ><i
        :class="
          scope.row.oldConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.oldConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.oldConsumersNumTit > 0
            ? "+" + scope.row.oldConsumersNumTit
            : scope.row.oldConsumersNumTit < 0
            ? "" + scope.row.oldConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="oldAmountNum"
    label="其他"
    sortable
    :width="0.21 * $bodyWidth"
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.unkownConsumersNum }}</span
      ><i
        :class="
          scope.row.unkownConsumersNumTit > 0
            ? 'num-badge-blue'
            : scope.row.unkownConsumersNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.unkownConsumersNumTit > 0
            ? "+" + scope.row.unkownConsumersNumTit
            : scope.row.unkownConsumersNumTit < 0
            ? "" + scope.row.unkownConsumersNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
</template>
            <template v-if="tabPosition5 == 2">
  <el-table-column
    prop="amountNum"
    :key="1"
    label="总计"
    sortable
    :width="0.21 * $bodyWidth"
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.amountNum }}</span
      ><i
        :class="
          scope.row.amountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.amountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.amountNumTit > 0
            ? "+" + scope.row.amountNumTit
            : scope.row.amountNumTit < 0
            ? "" + scope.row.amountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="childAmountNum"
    :key="2"
    label="儿童"
    sortable
    :width="0.21 * $bodyWidth"
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.childAmountNum }}</span
      ><i
        :class="
          scope.row.childAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.childAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.childAmountNumTit > 0
            ? "+" + scope.row.childAmountNumTit
            : scope.row.childAmountNumTit < 0
            ? "" + scope.row.childAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="juvenileAmountNum"
    :key="3"
    :width="0.21 * $bodyWidth"
    label="少年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.juvenileAmountNum }}</span
      ><i
        :class="
          scope.row.juvenileAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.juvenileAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.juvenileAmountNumTit > 0
            ? "+" + scope.row.juvenileAmountNumTit
            : scope.row.juvenileAmountNumTit < 0
            ? "" + scope.row.juvenileAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="youthAmountNum"
    :key="4"
    :width="0.21 * $bodyWidth"
    label="青年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.youthAmountNum }}</span
      ><i
        :class="
          scope.row.youthAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.youthAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.youthAmountNumTit > 0
            ? "+" + scope.row.youthAmountNumTit
            : scope.row.youthAmountNumTit < 0
            ? "" + scope.row.youthAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="middleAmountNum"
    :key="5"
    :width="0.21 * $bodyWidth"
    label="中年"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.middleAmountNum }}</span
      ><i
        :class="
          scope.row.middleAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.middleAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.middleAmountNumTit > 0
            ? "+" + scope.row.middleAmountNumTit
            : scope.row.middleAmountNumTit < 0
            ? "" + scope.row.middleAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="oldAmountNum"
    :key="6"
    label="老年"
    :width="0.21 * $bodyWidth"
    sortable
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.oldAmountNum }}</span
      ><i
        :class="
          scope.row.oldAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.oldAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.oldAmountNumTit > 0
            ? "+" + scope.row.oldAmountNumTit
            : scope.row.oldAmountNumTit < 0
            ? "" + scope.row.oldAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
  <el-table-column
    prop="oldAmountNum"
    label="其他"
    sortable
    :width="0.21 * $bodyWidth"
  >
    <template slot-scope="scope">
      <span class="num-box">{{ scope.row.unkownAmountNum }}</span
      ><i
        :class="
          scope.row.unkownAmountNumTit > 0
            ? 'num-badge-blue'
            : scope.row.unkownAmountNumTit < 0
            ? 'num-badge-orag'
            : 'none'
        "
        >{{
          scope.row.unkownAmountNumTit > 0
            ? "+" + scope.row.unkownAmountNumTit
            : scope.row.unkownAmountNumTit < 0
            ? "" + scope.row.unkownAmountNumTit
            : ""
        }}</i
      >
    </template>
  </el-table-column>
</template>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PieSupervise"; //饼图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import Baidumap from "@/components/baidumap/index.vue";
import BarChart from "@/components/charts/bar";
import PieEpide from "@/components/charts/pieHomeEpide";
import Tab from "@/components/tab.vue";
import {
  keepStatistics,
  moneyStatistics,
  busStatistics,
  fundSupervisionAdd,
  insuranceBankStatistics,
} from "@/api/homesupervise";
import { cardType } from "@/api/common";
import {
  consumerStatistics,
  regionalDistributionInfo,
  consumerAmountTrend,
  consumerAmountCumulative,
  consumerAmountCityStatistics,
  consumerAmountIndustryStatistics,
  regionalConsumerInfo,
} from "@/api/homeConsumptionstructure";
import Pmap from "@/components/charts/map.vue"; //地图

export default {
  name: "homeConsumptionstructure",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      barChart2: false,
      optionData: [
        { name: "消费人次", haripinType: 1 },
        { name: "预付卡交易金额", haripinType: 2 },
      ],
      outing: [],
      options: [],
      forCodeData: [],
      forCodeDatacolor: [
        "#125fe0",
        "#208dfd",
        "#18bbff",
        "#00ff5a",
        "#c6ff00",
        "#fff100",
      ],
      typeName2: "资金",
      typeName3: "资金",
      typeName4: "资金",
      typeName5: "资金",
      tabPosition1: 1,
      tabPosition2: 1,
      tabPosition3: 1,
      tabPosition4: 1,
      tabPosition5: 1,
      isTowPie: false,
      statisticsData: [],
      cunguan: "",
      baoxian: "",
      units1: ["", "", "", "", ""],
      units2: ["", "", "", "", ""],
      pieChartData: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "儿童",
            data: [],
          },
          {
            name: "少年",
            data: [],
          },
          {
            name: "青年",
            data: [],
          },
          {
            name: "中年",
            data: [],
          },
          {
            name: "老年",
            data: [],
          },
        ],
      },
      barChartDatatwo: {
        xAxisData: [],
        seriesData: [
          {
            name: "儿童",
            data: [],
          },
          {
            name: "少年",
            data: [],
          },
          {
            name: "青年",
            data: [],
          },
          {
            name: "中年",
            data: [],
          },
          {
            name: "老年",
            data: [],
          },
        ],
      },
      legendData: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData: ["元", "元"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData2: ["元", "元"],
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      showAll3: false,
      tableDataAll3: [],
      tableData3: [],
      pieSmallChartData: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      pieSmallChartData2: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    tabPosition1: function (val) {
      //this.isTowPie = false
      this.getInsuranceBankStatistics();
      if (val == 1) {
        this.moneyStatistics();
      } else {
        this.busStatistics();
      }
    },
    radio1: function (val) {
      this.fundSupervisionAdd();
      this.getconsumerAmountTrend();
    },
    tabPosition2: function (val) {
      this.fundSupervisionAdd();
      this.getconsumerAmountTrend();
      this.legendData =
        val == 0
          ? ["存管商户发卡金额", "保险商户发卡金额"]
          : ["存管商户数量", "保险商户数量"];
      this.unitData = val == 0 ? ["元", "元"] : ["家", "家"];
      this.typeName2 = val == 0 ? "资金" : "商户";
    },
    radio2: function (val) {
      this.getconsumerAmountCumulative();
    },
    tabPosition3: function (val) {
      this.getconsumerAmountCumulative();
      this.legendData2 =
        val == 0
          ? ["存管商户发卡金额", "保险商户发卡金额"]
          : ["存管商户数量", "保险商户数量"];
      this.unitData2 = val == 0 ? ["元", "元"] : ["家", "家"];
      this.typeName3 = val == 0 ? "资金" : "商户";
    },
    tabPosition4: function (val) {
      this.getconsumerAmountCityStatistics();
    },
    tabPosition5: function (val) {
      this.consumerAmountIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    showRow({ row, rowIndwx }) {
      console.log(row, rowIndwx);
      let styleJson = {
        marginBottom: 5,
        borderRadius: 5,
      };

      // if (row.show) {
      //   styleJson = {
      //     display: "block",
      //   };
      // } else {
      //   styleJson = {};
      // }
      return styleJson; // 返回对象
    },
    popupVal(val, row) {
      this.tabPosition2 = val.haripinType;
      // console.log("val=====", val)
    },
    popupVal2(val) {
      this.radio1 = val;
      // console.log(val);
      // console.log(row);
    },
    popupVal3(val, row) {
      this.tabPosition3 = val.haripinType;
    },
    popupVal4(val) {
      this.radio2 = val;
    },
    popupVal5(val) {
      this.tabPosition4 = val;
    },
    popupVal6(val) {
      this.tabPosition5 = val;
    },

    intData() {
      this.consumerStatistics();
      this.cardType();
      this.getconsumerAmountTrend();
      this.getconsumerAmountCumulative();
      this.getconsumerAmountCityStatistics();
      this.consumerAmountIndustryStatistics();
      this.regionalConsumerInfo();
      // this.keepStatistics();
      this.moneyStatistics();
      this.fundSupervisionAdd();
      this.getInsuranceBankStatistics();
    },
    consumerStatistics() {
      consumerStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        this.forCodeData = [
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childConsumersRate,
              },
              {
                name: "少年",
                value: res.data.juvenileConsumersRate,
              },
              {
                name: "青年",
                value: res.data.youthConsumersRate,
              },
              {
                name: "中年",
                value: res.data.middleConsumersRate,
              },
              {
                name: "老年",
                value: res.data.oldConsumersRate,
              },
              {
                name: "其他",
                value: res.data.unkownAgeConsumersRate,
              },
            ],
            xfrq: res.data.consumersNum,
            xfrqcom: "人次",
            Gender: [
              {
                name: "男",
                value: res.data.maleConsumersRate,
              },
              {
                name: "女",
                value: res.data.femaleConsumersRate,
              },
              {
                name: "其他",
                value: res.data.unkonwConsumersRate,
              },
            ],
            type: 1,
          },
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childAmountRate,
              },
              {
                name: "少年",
                value: res.data.juvenileAmountRate,
              },
              {
                name: "青年",
                value: res.data.youthAmountRate,
              },
              {
                name: "中年",
                value: res.data.middleAmountRate,
              },
              {
                name: "老年",
                value: res.data.oldAmountRate,
              },
              {
                name: "其他",
                value: res.data.unkownAmountRate,
              },
            ],
            xfrq: res.data.amountNum,
            xfrqcom: "元",
            Gender: [
              {
                name: "男",
                value: res.data.maleAmountRate,
              },
              {
                name: "女",
                value: res.data.femaleAmountRate,
              },
              {
                name: "其他",
                value: res.data.unkownAmountRate,
              },
            ],
            type: 2,
          },
        ];
      });
    },
    regionalConsumerInfo() {
      this.showbmap = true;
      this.showmap = true;
      regionalConsumerInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.amountNum,
              childAmountNum: item.childAmountNum,
              juvenileAmountNum: item.juvenileAmountNum,
              youthAmountNum: item.youthAmountNum,
              middleAmountNum: item.middleAmountNum,
              oldAmountNum: item.oldAmountNum,
              unkownAgeAmountNum: item.unkownAgeAmountNum,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getconsumerAmountTrend() {
      this.barChart1 = true;
      consumerAmountTrend({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition2,
        type: this.radio1,
      })
        .then((res) => {
          this.barChart1 = false;
          this.barChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name: "儿童",
                data:
                  this.tabPosition2 == 1
                    ? res.data.childConsumerTrend
                    : res.data.childAmountTrend,
              },
              {
                name: "少年",
                data:
                  this.tabPosition2 == 1
                    ? res.data.juvenileConsumerTrend
                    : res.data.juvenileAmountTrend,
              },
              {
                name: "青年",
                data:
                  this.tabPosition2 == 1
                    ? res.data.youthConsumerTrend
                    : res.data.youthAmountTrend,
              },
              {
                name: "中年",
                data:
                  this.tabPosition2 == 1
                    ? res.data.middleConsumerTrend
                    : res.data.middleAmountTrend,
              },
              {
                name: "老年",
                data:
                  this.tabPosition2 == 1
                    ? res.data.oldConsumerTrend
                    : res.data.oldAmountTrend,
              },
              {
                name: "其他",
                data:
                  this.tabPosition2 == 1
                    ? res.data.unkownConsumerTrend
                    : res.data.unkownAmountTrend,
              },
            ],
          };
          this.units1 =
            this.tabPosition2 == 1
              ? ["", "", "", "", "", ""]
              : ["元", "元", "元", "元", "元", "元"];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getconsumerAmountCumulative() {
      this.barChart2 = true;
      consumerAmountCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition3,
        type: this.radio2,
      })
        .then((res) => {
          this.barChart2 = false;
          this.barChartDatatwo = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name: "儿童",
                data:
                  this.tabPosition3 == 1
                    ? res.data.childConsumerTrend
                    : res.data.childAmountTrend,
              },
              {
                name: "少年",
                data:
                  this.tabPosition3 == 1
                    ? res.data.juvenileConsumerTrend
                    : res.data.juvenileAmountTrend,
              },
              {
                name: "青年",
                data:
                  this.tabPosition3 == 1
                    ? res.data.youthConsumerTrend
                    : res.data.youthAmountTrend,
              },
              {
                name: "中年",
                data:
                  this.tabPosition3 == 1
                    ? res.data.middleConsumerTrend
                    : res.data.middleAmountTrend,
              },
              {
                name: "老年",
                data:
                  this.tabPosition3 == 1
                    ? res.data.oldConsumerTrend
                    : res.data.oldAmountTrend,
              },
              {
                name: "其他",
                data:
                  this.tabPosition3 == 1
                    ? res.data.unkownConsumerTrend
                    : res.data.unkownAmountTrend,
              },
            ],
          };
          this.units2 =
            this.tabPosition3 == 1
              ? ["", "", "", "", "", ""]
              : ["元", "元", "元", "元", "元", "元"];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    keepStatistics() {
      keepStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          this.forCodeData = [
            {
              seriesData: [
                {
                  name: "儿童",
                  value: 37,
                  man: 60,
                  woman: 40,
                },
                {
                  name: "少年",
                  value: 50,
                  man: 40,
                  woman: 60,
                },
                {
                  name: "青年",
                  value: 70,
                  man: 20,
                  woman: 80,
                },
                {
                  name: "中年",
                  value: 37,
                  man: 30,
                  woman: 70,
                },
                {
                  name: "老年",
                  value: 37,
                  man: 10,
                  woman: 90,
                },
              ],
              xfrq: 1231233,
              xfrqcom: "人",
              type: 1,
            },
            {
              seriesData: [
                {
                  name: "儿童",
                  value: 60,
                  man: 60,
                  woman: 40,
                },
                {
                  name: "少年",
                  value: 10,
                  man: 40,
                  woman: 60,
                },
                {
                  name: "青年",
                  value: 20,
                  man: 20,
                  woman: 80,
                },
                {
                  name: "中年",
                  value: 45,
                  man: 30,
                  woman: 70,
                },
                {
                  name: "老年",
                  value: 90,
                  man: 10,
                  woman: 90,
                },
              ],
              xfrq: 11111111,
              xfrqcom: "元",
              type: 2,
            },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInsuranceBankStatistics() {
      let _this = this;
      insuranceBankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('保险资金各银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData2.seriesData = arr;
        });
      });
    },
    toOnePie() {
      if (this.tabPosition1 == 1) {
        this.moneyStatistics();
      } else if (this.tabPosition1 == 2) {
        this.busStatistics();
      }
      this.isTowPie = false;
    },
    moneyStatistics() {
      let _this = this;
      moneyStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('资金占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    busStatistics() {
      let _this = this;
      busStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('商户占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    fundSupervisionAdd() {
      fundSupervisionAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {});
    },
    getconsumerAmountCityStatistics() {
      let _this = this;
      this.loading1 = true;
      consumerAmountCityStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition4,
      }).then((res) => {
        console.log(res.data[0].consumersNum);
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      consumerAmountCityStatistics({
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
        infoType: this.tabPosition4,
      }).then((res) => {
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    consumerAmountIndustryStatistics() {
      this.loading2 = true;
      consumerAmountIndustryStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition5,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      consumerAmountIndustryStatistics({
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
        infoType: this.tabPosition5,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    PieSmallChart,
    PieEpide,
    Pmap,
    Tab,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.homeConsumptionstructure {
  // 四色码
  .idiot-one {
    margin-bottom: 30px;
    background: white;
    // display: flex;
    // justify-content: space-around;
    // height: 212px;
    // margin: 0 30px 30px 30px;
    // border: 1px solid #17688d;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(43, 202, 255, 0.1),
    //   #0000,
    //   rgba(43, 202, 255, 0.1)
    // );
    .box {
      width: 100%;
      text-align: center;
      display: flex;
      .box-left {
        width: 40%;
        height: 470px;
        font-size: 30px;
        color: #312d37;
        .box-left-childtop {
          margin: 150px 0 28px 60px;
          text-align: left;
        }
        .box-left-childtopval {
          text-align: left;
          margin: 0 0 18px 60px;
        }
      }
      .box-right {
        width: 60%;
        height: 470px;
        position: relative;
        .box-left-childbottom {
          position: absolute;
          bottom: 3.6vw;
          left: 20px;
          display: flex;
          flex-wrap: wrap;
          .box-left-childbottomval {
            margin-right: 5px;
            font-size: 24px;
            color: #312d37;
            width: 48%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .box-left-childbottomvalicon {
              width: 12px;
              height: 12px;
              margin-right: 12px;
            }
          }
        }
      }
      // .box-url {
      //   width: 160px;
      //   height: 160px;
      //   background: url("../assets/imgs/pieback.png") no-repeat;
      //   background-size: 100% 100%;
      //   position: absolute;
      //   right: 62px;
      //   top: 26px;
      // }
      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
      }
    }
    .box:nth-child(1) {
      border-bottom: 1px dashed #eeeeee;
    }
  }
  .pie-box {
    margin-bottom: 30px;
    // background: url("../assets/imgs/pie-bg3.png") no-repeat;
    // background-size: 100% 100%;
    // margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
